@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Rubik", sans-serif;
  }
  body {
    font-family: "Rubik", sans-serif;
  }
}
